import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";

function AnnualReports() {
  const [showCount, setShowCount] = React.useState(3);

  const data = useStaticQuery(graphql`
    query AnnualReportsQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "stay-informed"}}) {
        frontmatter {
          annualReports {
            link
            year
            previewImage
            title
          }
        }
      }
    }
  `);
  const {annualReports} = data.markdownRemark.frontmatter;

  const onLoadMore = () => {
    setShowCount(Math.min(annualReports.length, showCount + 3));
  }

  const hasMore = showCount < annualReports.length;

  return (
    <div id="annual-report" className="mb-20">
      <div className="text-center pt-10 pb-20">
        <EmphasisTextUnderlined strokeType="strokeSmallRed" containerClassName="mx-auto" className="w-36"/>
        <p className="pt-10 mb-10 text-5xl font-bold text-red-400">Annual Report</p>
      </div>

      <div className="flex flex-col relative w-full md:w-3/4 self-stretch mb-24 mx-auto">
        <div className="relative flex-grow">
          <div className="absolute top-10 left-0 -right-10 w-3/4 h-[110%] mx-auto bg-red-400 opacity-20"/>
          <img src={annualReports[0].previewImage} alt={annualReports[0].title} className="relative mx-auto w-3/4 h-full object-cover"/>
          <div className="absolute top-0 left-0 right-0 w-3/4 h-full mx-auto bg-black opacity-10"/>
        </div>
        <a href={annualReports[0].link}
           className="relative text-center font-bold block p-5 bg-red-400 hover:bg-red-600 mx-auto w-3/4">
          {annualReports[0].title}
        </a>
      </div>

      <div className="md:flex flex-row flex-wrap justify-between md:ml-40 md:mr-40 mb-10">
        {annualReports.slice(1, 3).map(({title, previewImage, link}, index) => (
          <div key={index} className="flex flex-col relative w-full md:w-1/2 self-stretch mb-10">
            <div className="relative flex-grow">
              <div className="absolute top-10 left-0 -right-10 w-3/4 h-[110%] mx-auto bg-red-400 opacity-20"/>
              <img src={previewImage} alt={title} className="relative mx-auto w-3/4 h-full object-cover"/>
              <div className="absolute top-0 left-0 right-0 w-3/4 h-full mx-auto bg-black opacity-10"/>
            </div>
            <a href={link}
               className="relative text-center font-bold block p-5 bg-red-400 hover:bg-red-600 mx-auto w-3/4">
              {title}
            </a>
          </div>
        ))}
      </div>

      <div className="md:flex flex-row flex-wrap justify-between md:ml-40 md:mr-40">
        {annualReports.slice(3,showCount).map(({title, previewImage, link}, index) => (
          <div key={index} className="flex flex-col relative w-full md:w-1/3 self-stretch mb-10">
            <div className="relative flex-grow">
              <div className="absolute top-10 left-0 -right-10 w-3/4 h-[110%] mx-auto bg-red-400 opacity-20"/>
              <img src={previewImage} alt={title} className="relative mx-auto w-3/4 h-full object-cover"/>
              <div className="absolute top-0 left-0 right-0 w-3/4 h-full mx-auto bg-black opacity-10"/>
            </div>
            <a href={link}
               className="relative text-center font-bold block p-5 bg-red-400 hover:bg-red-600 mx-auto w-3/4">
              {title}
            </a>
          </div>
        ))}
      </div>

      {hasMore && (
        <button onClick={onLoadMore}
                className="font-bold text-white bg-red-400 hover:bg-red-600 rounded-full px-8 py-4 mx-auto block">
          LOAD MORE
        </button>
      )}
    </div>
  )
}

export default AnnualReports;
