import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import DownloadBox, {downloadBoxParams} from "../../components/downloadBox";
import Newsletters from "./newsletters";
import AuditedReports from "./auditedReport";
import AnnualReport from "./annualReport";
import RecentNewsPosts from "../../components/recentNewsPosts";

const StayInformedPage = (props) => {

  const data = useStaticQuery(graphql`
    query StayInformedPageQuery {
      stayInformed: markdownRemark(frontmatter: {templateKey: {eq: "stay-informed"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
      common: markdownRemark(frontmatter: {dataId: {eq: "common"}}) {
        frontmatter {
          socialMedia {
            link
            title
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.stayInformed.frontmatter.imageCaptionSection;
  const {socialMedia} = data.common.frontmatter;

  return (
    <Layout activeLink="/stay-informed" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <div className="py-10">
        <RecentNewsPosts/>
      </div>

      {socialMedia.map(({title, link}, index) => (
        <DownloadBox key={index} title={`Go to our ${title} page`} link={link}
                     linkLabel="Connect With Us" {...downloadBoxParams[index % downloadBoxParams.length]} />
      ))}

      <Newsletters/>
      <AnnualReport/>
      <AuditedReports/>

      <HelpMethodsSection/>
    </Layout>
  );
};

StayInformedPage.propTypes = {};

export default StayInformedPage;
