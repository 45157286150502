import React from "react";

export const downloadBoxParams = [
    {
      boxClasses: "border-blue-800",
      buttonClasses: "bg-blue-800 hover:bg-blue-600",
      titleClasses: "text-blue-800"
    },
    {
      boxClasses: "border-pink-600",
      buttonClasses: "bg-pink-600 hover:bg-pink-800",
      titleClasses: "text-pink-600"
    },
    {
      boxClasses: "border-green-600",
      buttonClasses: "bg-green-600 hover:bg-green-800",
      titleClasses: "text-green-600"
    }
  ];

function DownloadBox(
  {
    title, link, linkLabel, showDownloadIcon = false,
    boxClasses = "", buttonClasses = "", titleClasses = ""
  }) {
  return (
    <div className="px-5">
      <div className={`md:w-1/2 mx-auto rounded-sm border-4 m-20 p-10 shadow-xl ${boxClasses || "border-blue-400"}`}>
        <p className={`text-center text-3xl ${titleClasses}`}>{title}</p>
        {!!link && (
          <a href={link} className={`mt-10 block w-fit flex flex-row items-center justify-center py-2 px-8 rounded-full
          mx-auto text-white ${buttonClasses || "bg-blue-400 hover:bg-blue-600"}`}>
            {showDownloadIcon &&
              <svg style={{height: 16, width: 16}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="#fff"
                      d="M448 352v64h-384v-64h-64v128h512v-128zm-64-160h-96v-160h-64v160h-96l128 192z"></path>
              </svg>}
            <p className="p-5">{linkLabel}</p>
          </a>
        )}
      </div>
    </div>
  );
}

export default DownloadBox;
